import { Row, Col, Spinner, Badge, Pagination } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import getFileIcon from "../../../helpers/getFileIcon";
import { getDocuments } from "../../../network/profile";
import { addDocuments } from "../../../reducers/User";
import dayjs from 'dayjs'
import 'dayjs/locale/ru';
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);


function DocumentsList() {
  const dispatch = useDispatch()

  const { profile, documents } = useSelector(state => state.user)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams();

  const limit  = parseInt(searchParams.get("limit"))   || 50;
  const offset = parseInt(searchParams.get("offset"))  || 0;


  const fetchDocuments = async (limit, offset) => {
    setIsError(false)
    setIsLoading(true)   
    getDocuments({limit, offset},(err, resp) => {
        if(err) {
          setIsLoading(false)
          setIsError(true)
          return;
        }
        dispatch(addDocuments(resp));
        setIsLoading(false)
    });       
  }

  useEffect(() => {
    if(profile.ext_user_id) {

      fetchDocuments(limit, offset)
    }
  }, [limit, offset])


  const calculateUpperLimit = () => {
    if (documents.length === 0) {
      return 0;
    }
  
    return limit > documents.length ? offset + documents.length : offset + limit;
  };

  const upperLimit = calculateUpperLimit()

  const handlePageChange = (newOffset) => {
    setSearchParams({ limit, offset: newOffset })
  };

  function formatTimeDifference(start_on_tz, finish_on_tz) {
    if (!start_on_tz || !finish_on_tz) {
      return null;
    }

    const diffMs = dayjs(finish_on_tz).diff(dayjs(start_on_tz));
    const diff   = dayjs.duration(diffMs);

    if (diffMs < 1000) {
      return "Менее секунды"
    }
  
    return `${diff.hours()}ч ${diff.minutes()}м ${diff.seconds()}с`;
  }

  return (
    <div style={{ 
      paddingTop: '1.5rem',
      paddingBottom: '1rem',
    }}>
      {isLoading && (
        <Row className="d-flex p-2 mt-4" ><Spinner animation="border" variant="primary" /></Row>
      )}

      {isError && (
        <Row className="d-flex p-2 mt-4" ><h6 className="text-danger text-center p-4">Произошла ошибка. Обновите страницу</h6></Row>
      )}

      {(!isLoading && !isError) && (
        <>

        {documents && documents.length > 0 ? (
          <>
            <Row className='fw-bold py-2 gx-0'>
              <Col md={6} xs={10}>Имя файла</Col>
              <Col className="d-none d-md-block">Время обработки</Col>
              <Col xs={'auto'}>Статус</Col>
            </Row>
          
            <Row style={{ display: 'flex' }}>
              {documents.map((document) => (
                  <Row className='border-bottom py-2 px-3 gx-0'>
                  <Col md={6} >
                    <span style={{color: "gray",fontSize : "0.7em"}}>
                      #{document.report_id} |{' '}
                      {document.created_on_tz ? dayjs(document.created_on_tz).locale('ru').format('DD MMMM YYYY HH:mm') : 'Не указано'}
                    </span>
                    <div style={{marginTop: "6px", alignItems: 'center', verticalAlign: 'middle' }}>
                      {document.file_uri ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <a href={document.file_uri} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center' }}>
                            {getFileIcon(`${document.file_name}.${document.file_format}`)}
                            <h6 className="mb-1" style={{ marginLeft: '1rem', marginBottom: '0' }}>{document.file_name}.{document.file_format}</h6>
                          </a>
                        </div>
                        ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {getFileIcon(`${document.file_name}.${document.file_format}`)}
                          <h6 className="mb-1" style={{ marginLeft: '1rem', marginBottom: '0' }}>{document.file_name}.{document.file_format}</h6>
                        </div>
                        )}                           
                    </div>                      
                  </Col>
                  <Col className="pt-2 pt-md-0" style={{margin: "auto" }}>
                    <span style={{ verticalAlign: 'middle', fontSize: '0.9rem' }}>
                    {formatTimeDifference(document.start_on_tz, document.finish_on_tz)}
                    </span>
                  </Col>
      
                  <Col xs={"auto"} style={{margin: "auto"}}>
                    <div style={{ verticalAlign: 'middle' }}>
                      {
                        document.status == 1 ? <Badge bg="success">Сформирован</Badge> :
                        document.status == 2 ? <Badge bg="danger">Обработан с ошибкой</Badge> :
                        document.status == 0 ? <Badge bg="secondary">В очереди</Badge> :
                        null
                      }
                    </div>
                  </Col >
                </Row>
              ))}
            </Row>

          <Row className="d-flex py-3">
          <Col xs="auto" >
            <Pagination style={{ flexDirection: 'row' }}>
              <Pagination.Prev 
                disabled={offset === 0} 
                onClick={() => handlePageChange(Math.max(0, offset - limit))}
              />
              <div className="d-flex align-items-center justify-content-center text-center px-2">
                <b style={{ margin: 0, padding: 0 }}>{documents.length === 0 ? 0 : offset + 1}</b> - <b>{upperLimit}</b>
                {documents.length !== 0 && (
                  <p style={{ margin: 0, padding: 0, marginLeft: 4 }}>
                    из {upperLimit} {(offset + limit) == upperLimit && (`+`)}{" "}
                  </p>
                )}
              </div>
              <Pagination.Next 
                disabled={documents.length == 0 || limit > documents.length} 
                onClick={() => handlePageChange(offset + limit)} 
              />
            </Pagination>
          </Col>
        </Row>

          </>

        ) : (
          <h6 className="text-center">Список пуст</h6>
        )}
        </>
      )}
    </div>
  )
}

export default DocumentsList;