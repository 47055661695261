import { createSlice } from '@reduxjs/toolkit'
// import Cookies from 'js-cookie';

export const userSlice = createSlice({
    name: 'User',
    initialState: {
        isLogin : false,
        profile : {
            user_id: null,
            login: "",
            password: "",
            email: "",
            phone: "",
            is_active: "",
            server: "",
            ext_user_id: "",
            unix_updated_on: null,
            username: "",
            inns: ""
        },
        documents : []
    },
    reducers: {
        addProfile: (state, action) => {
            state.profile = action.payload;
        },
        addDocuments: (state, action) => {
            state.documents = action.payload;
        }
    },
});

export const { addProfile, addDocuments } = userSlice.actions;

export default userSlice.reducer;