import { Container, Row, Col, Card, Spinner, Image, Nav, Button } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams  } from "react-router-dom";
import { getProfile } from "../../network/profile";
import { addProfile } from "../../reducers/User";
import Cookies from 'js-cookie';
import dslogo from '../../assets/dslogo.png'
import dayjs from 'dayjs'
import 'dayjs/locale/ru';
import DocumentsList from "./components/documentsList";


function Profile() {
    document.title = "Д24 | Профиль";

    const dispatch = useDispatch()


    const [isLoading, setIsLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();

    const { profile } = useSelector(state => state.user)

    const tab = searchParams.get('tab') || 'docs'; 
    const ref = searchParams.get('ref')

    const fetchProfile = async () => {
      setIsLoading(true)
      getProfile({},(err, resp) => {
          if(err) {
            Cookies.remove("login")
            Cookies.remove("hash")
            window.location.replace('https://auth.ds24.ru/')
            return;
          }
          dispatch(addProfile(resp));
          setIsLoading(false)
      });       
    }

    useEffect(() => {
      fetchProfile();
    },[]);

    const handleTabChange = (newTab) => {
      searchParams.set('tab', newTab);
      setSearchParams(searchParams);
    }

    const returnToServer = () => {
      const redirects = {
        acc:  `https://oico.app/acc`,
        debt: `https://ya.ds24.ru/debt`,
      };
      // Иначе это д24 по умолчанию
      const DS24_server = profile.server == 'DS24_DEV' ? 'https://s0.ds24.ru/' : 'https://ds24.ru/'

      const redirectUrl = redirects[ref] || `${DS24_server}ui/request`;
  
      window.location.href = redirectUrl;
    }

    return (
    <Container className="position-relative">

      {isLoading && (
        <Row className="d-flex p-2 mt-4 justify-content-center" ><Spinner animation="border" variant="primary" /></Row>
      )}

      {!isLoading && (
        <>
        <Row className="pt-3 px-3 d-flex">
          <Col>
              <Button className="flex-grow-1 flex-lg-grow-0" variant="outline-primary" onClick={() => returnToServer()}>Вернуться в систему</Button>
          </Col>
        </Row>

        <Row className="p-4" style={{ gap: '2.5rem' }}>

        <Row className="d-flex justify-content-center">
          <Card 
            className="p-4" 
            style={{ 
              boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.09)', 
              border: 'none' 
            }}
          >
            <Row className="d-flex gap-3 gap-lg-0">
              <Col xs={12} className="text-center">
                <Row><Col><Image width="120" height="120" src={dslogo} roundedCircle /></Col></Row>
                <Row className="pt-2">
                  <Card.Title style={{ fontSize: '1.4rem', marginBottom: '0' }}>{profile.username}</Card.Title>
                  <Card.Text className="text-muted" style={{ fontSize: '0.8rem', marginBottom: '0' }}>{profile.login}</Card.Text>
                </Row>
              </Col>

              <Row className="p-2 d-flex justify-content-around">
                <Col xs="auto">
                  <Row>
                    <Col xs='auto'>
                      <Card.Text className="text-muted" style={{ fontSize: '0.8em', marginBottom: '0' }}>телефон</Card.Text>
                      <Card.Text style={{ fontSize: '1.1rem', marginBottom: '0' }}>
                        {profile.phone ? profile.phone : 'не указан'}
                      </Card.Text>
                    </Col>
                    <Col xs='auto'>
                      <Card.Text className="text-muted" style={{ fontSize: '0.8em', marginBottom: '0' }}>почта</Card.Text>
                      <Card.Text style={{ fontSize: '1.1rem', marginBottom: '0' }}>
                        {profile.email ? profile.email : 'не указана'}
                      </Card.Text>
                    </Col>
                  </Row>           
                </Col>

                <Col xs="auto py-3 py-sm-0">
                  <Row>
                    <Col xs='auto'>
                      <Card.Text className="text-muted" style={{ fontSize: '0.8em', marginBottom: '0' }}>профиль обновлялся</Card.Text>
                      <Card.Text style={{ fontSize: '1.1rem', marginBottom: '0' }}>
                        {profile.unix_updated_on ? dayjs.unix(profile.unix_updated_on).locale('ru').format('DD MMMM YYYY') : 'никогда'}
                      </Card.Text>
                    </Col>
                  </Row>           
                </Col>
              </Row>
            </Row>
          </Card>
      </Row>

      <Row>
        <Col className="p-0 m-0">
          <Nav variant="tabs" activeKey={tab}>
            <Nav.Item><Nav.Link onClick={() => handleTabChange('docs')} eventKey="docs">Документы</Nav.Link></Nav.Item>
          </Nav>
        </Col>

        {tab == 'docs' && <DocumentsList />}
      </Row>

      </Row>
      </>
      )}
    </Container>
    );
}

export default Profile;