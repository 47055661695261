import fetch from 'node-fetch'

export async function getProfile({}, cb = () => {}) {
    const response = await fetch(`/api/user/profile`, {
        method: 'get'
    });
    if (response.ok) {
        const data = await response.json();
        cb(null,data);
    } else {
        cb(response.status);
    }
}

export async function getDocuments({limit = 50, offset = 0}, cb = () => {}) {
    const response = await fetch(`/api/user/profile/documents/?limit=${limit}&offset=${offset}`, {
        method: 'get'
    });
    if (response.ok) {
        const data = await response.json();
        cb(null,data);
    } else {
        cb(response.status);
    }
}
