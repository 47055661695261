import { createBrowserRouter, RouterProvider, useNavigate, useLocation} from "react-router-dom";
import Login from './Login';
import Profile from "./Profile";

/**
 * Роутер всего приложения
 */

const router = createBrowserRouter([
    // старое апп, которое не надо трогать
    { path: "/", element: <Login /> },
    // новый компонент про профиль юзера
    { path: "/profile", element: <Profile /> },
    // ниже добавлять другие роуты 
]);

function Router() {
    
    return (
        <RouterProvider router={router} />
    );
}

export default Router;
