import React from 'react';
import icon_image from '../assets/icons/icon_image.svg';
import icon_doc from '../assets/icons/icon_doc.svg';
import icon_pdf from '../assets/icons/icon_pdf.svg';
import icon_csv from '../assets/icons/icon_csv.svg';
import icon_xls from '../assets/icons/icon_xls.svg';
import icon_zip from '../assets/icons/icon_zip.svg';
import icon_video from '../assets/icons/icon_video.svg';


export default function getFileIcon(filename) {
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex !== -1) {
    const name = filename.slice(0, lastDotIndex);
    const extension = filename.slice(lastDotIndex + 1).toLowerCase();
    const thumbnailUrl = `${name}_th.${extension}`;

    switch (extension) {
      case 'jpg':
      case 'jpeg':
        // Предполагается, что это изображение, и возвращаем его в виде тега <img>
        return <img src={thumbnailUrl} alt="preview" style={{ width: '70px', height: '70px', borderRadius: '4px', objectFit: 'cover' }} />;
      case 'doc':
      case 'docx':
        return <img src={icon_doc} alt="document" style={{ width: '40px', height: '40px' }} />;
      case 'pdf':
        return <img src={icon_pdf} alt="pdf" style={{ width: '40px', height: '40px' }} />;
      case 'xls':
      case 'xlsx':
        return <img src={icon_xls} alt="excel" style={{ width: '40px', height: '40px' }} />;
      case 'csv':
        return <img src={icon_csv} alt="csv" style={{ width: '40px', height: '40px' }} />;
      case 'zip':
        return <img src={icon_zip} alt="zip" style={{ width: '40px', height: '40px' }} />;
      case 'mp4':
        return <img src={icon_video} alt="video" style={{ width: '40px', height: '40px' }} />;
      default:
        return <img src={icon_image} alt="image" style={{ width: '40px', height: '40px' }} />;
    }
  }

  // Если расширение не найдено, возвращаем иконку для изображения по умолчанию
  return <img src={icon_image} alt="default" style={{ width: '40px', height: '40px' }} />;
}