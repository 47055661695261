import React, { useState } from "react";
import { Link, BrowserRouter as Router } from "react-router-dom";
import { NavigationMenu } from "../../NavigationMenu";
import { MenuItem } from "../../NavigationMenu/MenuItem";
import { MenuItemIcon } from "../../NavigationMenu/MenuItemIcon";
import { MenuItemText } from "../../NavigationMenu/MenuItemText";
import { D24Icon } from "./D24Icon";

export const ServicesMenu = ({ servicesOptions, currentIdx, setCurrent }) => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    // PAU нах тут роутер я хз ...
    // <Router>
      <NavigationMenu {...{ openMenu, setOpenMenu }}>
        {servicesOptions.map(({ title, note, href, name }, idx) => (
          <Link key={idx} to={href}>
            <MenuItem
              name={name}
              selected={idx === currentIdx}
              onClick={() => {
                setCurrent(idx);
                setOpenMenu(false);
              }}
            >
              <MenuItemIcon>
                <D24Icon />
              </MenuItemIcon>
              <MenuItemText primaryText={title} secondaryText={note} />
            </MenuItem>
          </Link>
        ))}
      </NavigationMenu>
    // </Router>
  );
};
